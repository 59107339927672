import React from 'react'
import { Head } from '~/components'
import Menu from '~/components/Menu'
import Banner from '~/components/Banner'

import bannerPolitica from '~/assets/img/banner-politica.jpg'
import Footer from '~/components/Footer'
import TermosDeUsoContent from '~/components/TermosDeUsoContent'

const TermosDeUso = () => {
  return (
    <>
      <Head />
      <Banner
        image={bannerPolitica}
        title="Políticas Dimas Sempre"
        maxWidth={880}
      />
      <TermosDeUsoContent />
      <Footer />
    </>
  )
}

export default TermosDeUso
